@import url('https://fonts.googleapis.com/css2?family=Road+Rage&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bahianita&display=swap');
@import './anims.scss';
.app {
  text-align: center;
  background-color: rgba(63, 35, 70, 0.8);
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  z-index: 0;
  flex-direction: column;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
  .bg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    background-color: rgba(63, 35, 70, 0.8);
    .hell {
      position: absolute;
      mix-blend-mode: luminosity;

      height: 100vh;
      width: 100vw;
      z-index: 0;
      opacity: 0.2;
      
      img {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
      }
    }
    .smoke {
      position: absolute;
      mix-blend-mode: color-dodge;

      img {
        height: 105vh;
        width: 100vw;
        object-fit: cover;
      }
    }
    .skeleton {
      position: absolute;
      left: -20px;
      bottom: 0px;
      width: 250px;
      overflow: hidden;
      transition: opacity 0.25s ease-in-out;
      .skel {
        img {
          transform: translateY(10px);
          width: 100%;
          object-fit: cover;
          filter: drop-shadow(3px 3px 5px);
        }
      }
      .gasmask {
        position: absolute;
        z-index: 1;
        width: 165px;
        mix-blend-mode: luminosity;
        img {
          transform: translateX(85px) translateY(60px);
          width: 100%;
          object-fit: cover;
          filter: drop-shadow(3px 3px 5px) blur(1px);
          animation: idle 0.9s infinite;
          opacity: 0.9;
        }
      }
    }
    .demon {
      position: absolute;
      top: -15px;
      mix-blend-mode: luminosity;
      overflow: hidden;
      width: 100vw;
      img {
        animation: go 20s alternate infinite ease-in-out;
        filter: drop-shadow(3px 3px 5px);
        object-fit: cover;
      }
    }
  }

  .home {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    scroll-snap-align: start;
    z-index: 1;
    flex-direction: column;
    .logo {
      height: 20vh;
      padding-bottom: 50px;
      z-index: 1;
      img {
        height: 100%;
        filter: drop-shadow(1px 1px 2px rgb(225, 125, 255));
      }
    }
    .player {
      z-index: 1;
      filter: drop-shadow(5px 5px 5px rgb(225, 125, 255));
    }
  }
  .discography {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    scroll-snap-align: start;
    z-index: 1;
    flex-direction: column;
    .list {
      width: 100vw;
      z-index: 1;
      li {
        display: flex;
        align-items: center;
        flex-direction: column;

        .art {
          height: 20vh;
          filter: drop-shadow(5px 5px 5px rgb(225, 125, 255));
        }
      }
    }
  }
  .apperances {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    z-index: 1;
    flex-direction: column;
    flex-wrap: wrap;
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      ul {
        padding: 5px;
        list-style-type: none;
        z-index: 1;
        .titles {
          display: flex;
          a {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 100px;
              filter: drop-shadow(5px 5px 5px rgb(225, 125, 255));
            }
          }
        }
        .artists {
          a {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
  .links {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    z-index: 1;
    flex-direction: column;
    ul {
      padding: 0;
      z-index: 1;
      list-style: none;
    }
  }
}

h1 {
  font-family: 'Road Rage', cursive;
  font-size: 7vmax;
  filter: drop-shadow(0px 0px 1px #fff) drop-shadow(2px 2px 3px #000);
  user-select: none;
}

h2 {
  font-family: 'Road Rage', cursive;
  font-size: 5vmax;
  filter: drop-shadow(0px 0px 1px #fff) drop-shadow(2px 2px 3px #000);
  user-select: none;
}
img {
  user-select: none;
}

span {
  font-family: 'Bahianita', cursive;
  font-size: 3vmax;
  filter: drop-shadow(2px 2px 3px rgb(218, 111, 240));
  color: #fff;
}
a {
  text-decoration: none;
}

@keyframes idle {
  0% {
    transform: translateX(85px) translateY(60px);
  }
  25% {
    transform: translateX(90px) translateY(58px);
  }
  75% {
    transform: translateX(85px) translateY(62px);
  }
  100% {
    transform: translateX(85px) translateY(60px);
  }
}

@keyframes go {
  0% {
    transform: translateX(1100px);
  }
  100% {
    transform: translateX(-1100px);
  }
}
